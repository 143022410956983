/// <reference path="../../../../node_modules/pixi.js/pixi.js.d.ts" />
import bunnyImage from "../../../images/bunny.png"
import { IExperimentApp } from "../Experiment"
import { AbstractCanvasApp } from "./AbstractCanvasApp"

interface BunnyProps {
  bounds: Bounds
  progress?: number
  controlX: number
  controlY: number
}

class Bunny {
  public bounds: Bounds
  public progress: number
  public controlX: number
  public controlY: number
  private w: number
  private rand: number;
  private h: number
  public sprite: PIXI.Sprite
  constructor(props: BunnyProps, texture?: PIXI.Texture ) {
    this.sprite=new PIXI.Sprite(texture)
    this.bounds = props.bounds
    this.progress = props.progress ? props.progress : 0
    this.sprite.position.y=props.bounds.bottom
    this.w = Math.abs(this.bounds.right - this.bounds.left)
    this.h =  Math.abs(this.bounds.bottom - this.bounds.top)
    this.controlX= props.controlX
    this.controlY= props.controlY
    this.rand= Math.sin(this.progress)*10 //+ Math.random()*Math.PI
  }
  public updatePosition = (t:number) => {
    t = ((t+this.progress*10000)%10000)/10000 //this.line
    // easing:
    t*=t
    const xOffset = Math.sin(this.rand*t*3)*5
    const yOffset = Math.cos(this.rand*t*3)*10
    const x = (1 - t) * (1 - t) * this.bounds.left + 2 * (1 - t) * t * this.controlX + t * t * this.bounds.right;
    const y = (1 - t) * (1 - t) * this.bounds.bottom + 2 * (1 - t) * t * this.controlY + t * t *  this.bounds.top;
    this.sprite.position.set(x+xOffset,y+yOffset)
    const scale = (1-(y+1)/this.h)*2
    this.sprite.scale.set(scale,scale)
  }
}


interface Bounds {
  top: number
  left: number
  right: number
  bottom: number
}

export default class GummyBunniesApp extends AbstractCanvasApp implements IExperimentApp {
  private total: number = 1000
  private perLine: number = 25
  private bunnies: Bunny[]
  private controlX: number
  private controlY: number
  private width: number
  private height: number
  app: PIXI.Application
  stopped: boolean

  start() {
    this.stopped = false
    this.app = new PIXI.Application({
    width:  window.innerWidth || window.screen.availWidth,
    height: window.innerHeight || window.screen.availHeight,
    transparent: false,
    view: this.canvas })

    this.bunnies = []
    this.width = window.innerWidth || window.screen.availWidth
    this.height = window.innerHeight || window.screen.availHeight
    const img = bunnyImage
    const bounds = {
      left: -0.2 * this.width,
      top: -0.1 * this.width,
      right: this.width + 0.1 * this.width,
      bottom: this.height - 0.1 * this.width
    }
    const tex: PIXI.Texture = PIXI.Texture.from(img)
    this.controlX = bounds.right + this.width / 3
    this.controlY = bounds.bottom + 100
    for (let i = 0; i < this.total; i++) {
      const bunny: Bunny = new Bunny({
        bounds: {
          ...bounds,
          left: (bounds.left - this.width * i / this.total),
          right: (bounds.right - this.width * i / this.total),
        },
        controlX: this.controlX,
        controlY: this.controlY,
        progress: i / this.total * this.perLine
      }, tex)
      this.bunnies.push(bunny)
      bunny.sprite.tint = Math.random() * 0xFFFFFF
      this.app.stage.addChild(bunny.sprite)
    }
    requestAnimationFrame(this.update);
  }
  stop() {
    this.stopped = true
  }

  update = (t: number) => {
    this.controlY = this.controlY + (this.app.renderer.plugins.interaction.mouse.global.x*2 - this.controlY) *0.05
    for(let i=0; i<this.total; i++) {
      this.bunnies[i].controlX = this.controlX
      this.bunnies[i].controlX = this.controlY
      this.bunnies[i].updatePosition(t)
    }
    if(!this.stopped) {
      requestAnimationFrame(this.update)
    }
  }
}
