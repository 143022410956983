import * as React from "react"
import Layout from "../../components/layout"
import GummyBunnies from "../../components/experiments/GummyBunnies"
import { pixiDependency } from "../../components/experiments/Experiment"
import SEO from "../../components/seo"

const GummyBunniesPage = () => (
  <>
    <GummyBunnies dependencies={pixiDependency}/>
    <Layout>
        <SEO title="Experiment: Pixi.js Bunnies" keywords={[`pixi.js`, `webgl`]} />
        <h2 style={{color:'white'}}>1000 Gummy Bunnies</h2>
    </Layout>
  </>
)

export default GummyBunniesPage
