import * as React from "react"
import Experiment, { ExperimentProps } from "./Experiment"
import GummyBunniesApp from "./apps/GummyBunniesApp"

export default class GummyBunnies extends Experiment {
  constructor(props: ExperimentProps) {
    super(props)
    this.class = GummyBunniesApp
  }
}
